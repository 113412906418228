<template>
  <div class="cell-lot won-cmn__cell">
    <a
      class="cell-lot__link cell-lot__img-wrp"
      :href="lotLink"
      target="_blank"
      rel="nofollow noopener"
    >
      <ui-img
        class="cell-lot__img"
        :alt="lot.id"
        :title="lot.name"
        v-bind="lazyLotImg"
      />
    </a>

    <div class="won-cmn__cell-col">
      <span class="cell-lot__date won-cmn__str won-cmn__str_sec">
        <ui-icon icon="timer-alt" />
        {{ $fd(lot.endedAt, $t('DATE_FORMATS.MONTH_DAY_YEAR')) }}
      </span>
      <a
        class="
          cell-lot__name
          won-cmn__str
          won-cmn__str_nb
        "
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
        :title="lot.name"
      >
        {{ lot.name }}
      </a>
      <span class="won-cmn__str won-cmn__str_sec">
        {{ $t('VIN_FORMAT', { vin: lot.vin }) }}
        <ui-copy-button
          class="cell-lot__copy-vin-btn"
          :value="lot.vin"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Lot } from 'Models/Lot'
import { UiIcon, UiImg, UiCopyButton } from '@shelf.network/ui-kit'
import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'
import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'cell-lot',

  components: {
    UiImg,
    UiIcon,
    UiCopyButton,
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    }
  },

  computed: {
    ...mapGetters({
      userPlatform: `entities/user/${userGetters.PLATFORM}`,
    }),

    lazyLotImg () {
      if (this.lot.images[0]) {
        return lazyTinyImg(this.lot.images[0], true)
      }

      return { src: placeholderPath }
    },

    lotLink () {
      return `${this.userPlatform.clientUrl}/lot/${this.lot.id}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/won.scss";

.cell-lot {
  padding: 1em;

  &__img-wrp {
    margin-right: 0.9em;
  }

  &__img {
    --ui-img-border-radius: 5px;

    display: block;
    height: 5.4em;
    max-width: 6.5em;
    min-width: 6.5em;
  }

  &__name {
    text-decoration: none;
    color: $color-dark;
    font-size: 1.25em; // 17.5px when font-size: 14px
  }

  &__date {
    font-size: 0.85em; // 12px
    line-height: 1.33;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ka": {
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ru": {
    "VIN_FORMAT": "Vin: {vin}"
  },
  "uk": {
    "VIN_FORMAT": "Vin: {vin}"
  }
}
</i18n>
