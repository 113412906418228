<template>
  <btn-dropdown
    class="cell-vehicle-price"
    ref="bidDropdown"
    :show-icon="false"
    :is-read-only="readonly || !canEditBid"
    :is-disabled="isSubmitting"
    @close="!isSubmitting && clearForm()"
  >
    <span
      class="won-cmn__str won-cmn__str_sec"
      slot="btn-txt"
      :title="`${participation.offer} ${lot.currency}`"
    >
      {{ $fc(participation.offer, lot.currency) }}

      <ui-icon
        v-if="!readonly && canEditBid"
        class="cell-vehicle-price__edit-icon"
        icon="pen"
      />
    </span>

    <template slot="content-title">
      {{ $t('DROPDOWN_TITLE') }}
    </template>

    <template slot="content-main">
      <number-input
        class="cell-vehicle-price__dropdown-number-input"
        v-model="form.bid"
        :is-disabled="isSubmitting"
        :step="lot.bidStep"
        :min="minBid"
        :max="maxBid"
      />

      <p
        v-if="Number(form.bid) < minBid"
        class="cell-vehicle-price__err"
      >
        {{ $t('MIN_BID_ERR', {
          val: $fc(minBid, participation.currency)
        }) }}
      </p>
    </template>

    <template slot="content-action">
      <ui-button
        class="cell-vehicle-price__dropdown-action"
        :is-disabled="!canApplyFormBid || isSubmitting"
        @click="apply"
      >
        {{ $t('APPLY_BTN') }}
      </ui-button>
    </template>
  </btn-dropdown>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import BtnDropdown from 'Common/BtnDropdown'
import NumberInput from 'Common/NumberInput'

import { mapActions } from 'vuex'
import { wonActions } from '../store/types'

import { Participation } from 'Models/Participation'
import { showSuccess, showError } from 'Utils/notifications'

export default {
  name: 'cell-vehicle-price',
  components: {
    BtnDropdown,
    NumberInput,
    UiButton,
    UiIcon,
  },

  props: {
    participation: { type: Participation, required: true },
    readonly: { type: Boolean, default: false },
  },

  data () {
    return {
      form: { bid: 0 },
      isSubmitting: false,
    }
  },

  computed: {
    lot () {
      return this.participation.lot
    },

    canEditBid () {
      return this.participation.isControllable &&
        !this.participation.isRequestedBuyNow
    },

    minBid () {
      return (Number(this.participation.offer) || Number(this.lot.startPrice)) +
        Number(this.lot.bidStep)
    },

    maxBid () {
      return Number(this.participation.autoBidLimit) ||
        Number(this.participation.bidLimit)
    },

    canApplyFormBid () {
      return this.minBid <= this.maxBid &&
        this.form.bid >= this.minBid &&
        this.form.bid <= this.maxBid
    },
  },

  created () {
    this.$watch(
      () => ([
        this.participation.currentBid,
        this.lot.highestBid,
      ]),
      () => { this.clearForm() },
      { immediate: true },
    )
  },

  methods: {
    ...mapActions('ui/won', {
      updateParticipationBid: wonActions.UPDATE_PARTICIPATION_BID,
    }),

    clearForm () {
      const bid = Math.max(
        Number(this.participation.currentBid),
        Number(this.lot.highestBid),
      )
      const newBid = bid + Number(this.lot.bidStep)
      this.form.bid = newBid > this.maxBid ? this.maxBid : newBid
    },

    async apply () {
      this.isSubmitting = true
      try {
        await this.updateParticipationBid({
          participation: this.participation,
          bid: this.form.bid,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (error) {
        this.clearForm()
        showError(this.$t('APPLY_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isSubmitting = false

      this.close()
    },

    close () {
      this.$refs.bidDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.cell-vehicle-price {
  &__edit-icon {
    color: $color-ui-secondary;
    margin-left: 0.1em;
    position: relative;
    top: 0.09em;
    font-size: 22px;
    line-height: 0;
  }

  &__err {
    line-height: 1.5;
    font-size: 0.8em;
    margin-top: 0.2em;
    font-weight: bold;
    color: $color-flag-is-error;

    &:first-of-type {
      margin-top: 0.7em;
    }
  }

  &__btn-dropdown {
    /deep/ .btn-dropdown__toggle-txt {
      color: $color-sys-info;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DROPDOWN_TITLE": "Increase or edit bid",
    "APPLY_BTN": "Apply",
    "APPLY_SUCCESS_NOTIFY": "Bid applied.",
    "APPLY_FAILED_NOTIFY": "Bid could not be applied. Please try again or contact the system owner.",
    "MIN_BID_ERR": "Min: {val}"
  },
  "ka": {
    "DROPDOWN_TITLE": "ბიჯის ცვლილება",
    "APPLY_BTN": "ცვლილება",
    "APPLY_SUCCESS_NOTIFY": "ბიჯი დაფიქსირდა.",
    "APPLY_FAILED_NOTIFY": "ბიჯი ვერ დაფიქსირდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს.",
    "MIN_BID_ERR": "მინ: {val}"
  },
  "ru": {
    "DROPDOWN_TITLE": "Изменить ставку",
    "APPLY_BTN": "Применить",
    "APPLY_SUCCESS_NOTIFY": "Ставка изменена.",
    "APPLY_FAILED_NOTIFY": "Не удалось изменить ставку. Повторите попытку позже или свяжитесь с владельцем системы.",
    "MIN_BID_ERR": "Мин.: {val}"
  },
  "uk": {
    "DROPDOWN_TITLE": "Змінити ставку",
    "APPLY_BTN": "Застосувати",
    "APPLY_SUCCESS_NOTIFY": "Ставку змінено.",
    "APPLY_FAILED_NOTIFY": "Не вдалось змінити ставку {name}. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "MIN_BID_ERR": "Мін.: {val}"
  }
}
</i18n>
