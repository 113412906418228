<template>
  <list-grouped
    class="won-list"
    :list="participations"
    :list-total-count="totalCount"
    :group-by-date-field="groupByDateField"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="LIST_PAGE_LIMIT"
    :load-more="loadNextParticipations"
    :reset-on-change="template"
    :head-component="WonListHead"
    :head-component-props="{ template }"
    :item-component="WonListItem"
    :item-component-props="{ template }"
    :item-skeleton-component="WonListItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import WonListHead from './WonListHead'
import WonListItem from './WonListItem'
import WonListItemSkeleton from './WonListItemSkeleton'

import { mapGetters, mapActions } from 'vuex'
import { wonGetters, wonActions } from 'Components/Won/store/types'
import { LIST_TEMPLATES, LIST_PAGE_LIMIT, GROUP_BY_ENUM } from '../constants'

const GROUP_BY_TO_ITEM_FIELD = {
  [GROUP_BY_ENUM.deliveredAt]: 'winner.deliveredAt',
  [GROUP_BY_ENUM.endTime]: 'lot.endTime',
}

export default {
  name: 'won-list',
  components: {
    ListGrouped,
  },

  props: {
    groupBy: {
      type: String,
      default: '',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },

    template: {
      type: String,
      default: LIST_TEMPLATES.invoice,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  data: _ => ({
    WonListHead,
    WonListItem,
    WonListItemSkeleton,

    LIST_PAGE_LIMIT,
  }),

  computed: {
    ...mapGetters('ui/won', {
      participations: wonGetters.PARTICIPATIONS,
      totalCount: wonGetters.TOTAL_COUNT,
      isLoading: wonGetters.IS_LOADING,
      isNextLoading: wonGetters.IS_NEXT_LOADING,
    }),

    groupByDateField () {
      return GROUP_BY_TO_ITEM_FIELD[this.groupBy]
    },
  },

  methods: {
    ...mapActions('ui/won', {
      loadNextParticipations: wonActions.LOAD_NEXT_PARTICIPATIONS,
    }),
  }
}
</script>
