var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"won"},[_c('page-subnav',{staticClass:"won__subnav"},[_c('won-list-params',{staticClass:"won__subnav-action",attrs:{"value":{
        sort: _vm.$route.query.sort || '',
        broker: _vm.$route.query.broker || '',
      }},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"won__tab",attrs:{"active-class":"won__tab_active","to":{
          params: { tab: _vm.WON_URL_PARAMS_TABS.invoice },
          query: _vm.$route.query,
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('INVOICE_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"won__tab",attrs:{"active-class":"won__tab_active","to":{
          params: { tab: _vm.WON_URL_PARAMS_TABS.inTransit },
          query: _vm.$route.query,
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('IN_TRANSIT_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"won__tab",attrs:{"active-class":"won__tab_active","to":{
          params: { tab: _vm.WON_URL_PARAMS_TABS.done },
          query: _vm.$route.query,
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('DONE_TAB'))+"\n      ")])],1)],2),_vm._v(" "),_c('won-list',{staticClass:"won__list",attrs:{"group-by":_vm.groupingAlgorithm,"template":_vm.template},on:{"update-list-ask":_vm.loadParticipations}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }