<template>
  <div class="cell-delivery won-cmn__cell">
    <template v-if="isDelivered">
      <ui-countdown
        v-if="winner.deliveredAt"
        class="cell-delivery__countdown"
        :start="participation.lot.startTime"
        :end="winner.deliveredAt"
      />

      <template v-else>
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </template>
    </template>

    <template v-else>
      <span class="cell-delivery__refused-msg">
        {{ $t('REFUSED_MSG') }}
      </span>
    </template>
  </div>
</template>

<script>
import { UiCountdown } from '@shelf.network/ui-kit'
import { Participation } from 'Models/Participation'
import { Winner } from 'Models/Winner'

export default {
  name: 'cell-delivery',
  components: { UiCountdown },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  computed: {
    winner () {
      return this.participation.winner
    },

    isDelivered () {
      return this.winner.state === Winner.statesEnum.dealCompleted
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.cell-delivery {
  &__countdown {
    font-size: 1em;
    line-height: 1;
    display: flex;
    align-items: center;

    /deep/ .ui-countdown {
      &__icon {
        color: $color-ui-secondary;
      }

      &__text {
        margin-left: 0.4em;
      }
    }
  }

  &__refused-msg {
    color: $color-flag-is-error;
  }
}

</style>

<i18n>
{
  "en": {
    "REFUSED_MSG": "Refused to pay"
  },
  "ka": {
    "REFUSED_MSG": "გადახდა უარყოფილია"
  },
  "ru": {
    "REFUSED_MSG": "Отказано в оплате"
  },
  "uk": {
    "REFUSED_MSG": "Відмовлено в оплаті"
  }
}
</i18n>
