<template>
  <div class="won">
    <page-subnav class="won__subnav">
      <won-list-params
        class="won__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          broker: $route.query.broker || '',
        }"
        @input="onListParamsChange"
      />

      <template slot="right">
        <router-link
          class="won__tab"
          active-class="won__tab_active"
          :to="{
            params: { tab: WON_URL_PARAMS_TABS.invoice },
            query: $route.query,
          }"
        >
          {{ $t('INVOICE_TAB') }}
        </router-link>

        <router-link
          class="won__tab"
          active-class="won__tab_active"
          :to="{
            params: { tab: WON_URL_PARAMS_TABS.inTransit },
            query: $route.query,
          }"
        >
          {{ $t('IN_TRANSIT_TAB') }}
        </router-link>

        <router-link
          class="won__tab"
          active-class="won__tab_active"
          :to="{
            params: { tab: WON_URL_PARAMS_TABS.done },
            query: $route.query,
          }"
        >
          {{ $t('DONE_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <won-list
      class="won__list"
      :group-by="groupingAlgorithm"
      :template="template"
      @update-list-ask="loadParticipations"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import WonList from './components/WonList'
import WonListParams from './components/WonListParams'

import { wonActions } from 'Components/Won/store/types'
import { mapActions } from 'vuex'
import { LIST_TEMPLATES, GROUP_BY_ENUM } from './constants'

import isEqual from 'lodash/isEqual'

import {
  WON_URL_PARAMS_TABS,
  WON_TAB_DEFAULT_SORT,
  WON_QUERY_SORTS_BY_TAB,
  WON_QUERY_SORTS,
} from 'Constants/wonUrlParams'
import { Winner } from 'Models/Winner'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [WON_URL_PARAMS_TABS.invoice]: LIST_TEMPLATES.invoice,
  [WON_URL_PARAMS_TABS.inTransit]: LIST_TEMPLATES.inTransit,
  [WON_URL_PARAMS_TABS.done]: LIST_TEMPLATES.done,
})

export default {
  name: 'won',
  components: {
    PageSubnav,
    WonList,
    WonListParams,
  },

  data: _ => ({
    WON_URL_PARAMS_TABS,
    template: TAB_TO_TEMPLATE_MAP[WON_URL_PARAMS_TABS.invoice],
    groupingAlgorithm: '',
  }),

  computed: {
    tabDefaultSort () {
      return WON_TAB_DEFAULT_SORT[this.$route.params.tab]
    },
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return

        const newQuery = Object.assign({}, this.$route.query)

        if (!WON_QUERY_SORTS_BY_TAB[tab].includes(newQuery.sort)) {
          delete newQuery.sort
        }

        this.onListParamsChange(newQuery)
      }
    }
  },
  methods: {
    ...mapActions('ui/won', {
      loadParticipationsAction: wonActions.LOAD_PARTICIPATIONS,
    }),

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.broker = input.broker || undefined
      query.sort = input.sort === this.tabDefaultSort
        ? undefined
        : input.sort || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadParticipations()
    },

    async loadParticipations () {
      const tab = this.$route.params.tab
      const query = this.$route.query
      const payload = { filter: {} }

      payload.filter.broker = query.broker
      payload.sort = query.sort || WON_TAB_DEFAULT_SORT[tab]

      switch (tab) {
        case WON_URL_PARAMS_TABS.invoice:
          payload.filter['winner.state'] = [
            Winner.statesEnum.waitingForInvoice,
            Winner.statesEnum.waitingForPayment,
          ].join(',')
          break
        case WON_URL_PARAMS_TABS.done:
          payload.filter['winner.state'] = Winner.statesEnum.dealCompleted
          break
        case WON_URL_PARAMS_TABS.inTransit:
          payload.filter['winner.state'] = Winner.statesEnum.inTransit
          break
        default:
      }

      this.callLoadAction(payload)
    },

    async callLoadAction (payload) {
      await this.loadParticipationsAction(payload)

      this.template = TAB_TO_TEMPLATE_MAP[this.$route.params.tab]

      const sort = this.$route.query.sort || this.tabDefaultSort
      switch (sort) {
        case WON_QUERY_SORTS.lotEndTime:
        case WON_QUERY_SORTS.lotEndTimeDesc:
          this.groupingAlgorithm = GROUP_BY_ENUM.endTime
          break
        case WON_QUERY_SORTS.winnerDeliveredAt:
        case WON_QUERY_SORTS.winnerDeliveredAtDesc:
          this.groupingAlgorithm = GROUP_BY_ENUM.deliveredAt
          break
        default:
          this.groupingAlgorithm = ''
      }
    },
  },

  metaInfo () {
    const tabTitles = {
      [WON_URL_PARAMS_TABS.invoice]: this.$t('META_TITLE_INVOICE'),
      [WON_URL_PARAMS_TABS.inTransit]: this.$t('META_TITLE_IN_TRANSIT'),
      [WON_URL_PARAMS_TABS.done]: this.$t('META_TITLE_DONE'),
    }

    return {
      title: tabTitles[this.$route.params.tab],
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.won {
  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }

  &__list {
    // having overflow-x: auto also causes overflow-y to be 'auto'.
    // this leads to absolutely positioned elements are clipped
    // if they belong to a list that is lower than screen height:
    min-height: 100vh;
  }
}
</style>

<i18n>
{
  "en": {
    "DONE_TAB": "DONE",
    "INVOICE_TAB": "INVOICE",
    "IN_TRANSIT_TAB": "IN TRANSIT",
    "META_TITLE_DONE": "Done",
    "META_TITLE_INVOICE": "Invoice",
    "META_TITLE_IN_TRANSIT": "In Transit"
  },
  "ka": {
    "DONE_TAB": "ᲓᲐᲡᲠᲣᲚᲔᲑᲣᲚᲘ",
    "INVOICE_TAB": "ᲘᲜᲕᲝᲘᲡᲘ",
    "IN_TRANSIT_TAB": "ᲒᲖᲐᲨᲘ",
    "META_TITLE_DONE": "დასრულებული",
    "META_TITLE_INVOICE": "ინვოისი",
    "META_TITLE_IN_TRANSIT": "გზაში"
  },
  "ru": {
    "DONE_TAB": "ЗАВЕРШЕНЫ",
    "INVOICE_TAB": "СЧËТ",
    "IN_TRANSIT_TAB": "В ОБРАБОТКЕ",
    "META_TITLE_DONE": "Завершены",
    "META_TITLE_INVOICE": "Счёт",
    "META_TITLE_IN_TRANSIT": "В обработке"
  },
  "uk": {
    "DONE_TAB": "ЗАВЕРЩЕНІ",
    "INVOICE_TAB": "РАХУНОК",
    "IN_TRANSIT_TAB": "В ОБРОБЦІ",
    "META_TITLE_DONE": "Завершені",
    "META_TITLE_INVOICE": "Рахунок",
    "META_TITLE_IN_TRANSIT": "В обробці"
  }
}
</i18n>
