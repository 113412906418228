<template>
  <div class="cell-winner won-cmn__cell">
    <lazy-ava-img
      class="cell-winner__ava"
      :alt="participation.fullName"
      :title="participation.fullName"
      :src="participation.avatarLink"
    />

    <div class="won-cmn__cell-col">
      <participation-lead-link
        class="cell-winner__name"
        :participation="participation"
      />
    </div>
  </div>
</template>

<script>
// TODO: move to common or duplicate (it uses active-trades styles)?
import ParticipationLeadLink
  from 'Components/ActiveTrades/components/ParticipationLeadLink'

import { Participation } from 'Models/Participation'
import LazyAvaImg from 'Common/LazyAvaImg'

export default {
  name: 'cell-winner',
  components: {
    LazyAvaImg,
    ParticipationLeadLink,
  },
  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/won.scss";

.cell-winner {
  &__ava {
    width: 2.2em;
    height: 2.2em;
  }
}
</style>
