<template>
  <div class="cell-deposit won-cmn__cell">
    <span
      class="won-cmn__str"
      :title="`${participation.depositAmount} ${participation.depositCurrency}`"
    >
      <deposit-payments-hint
        :deposit-id="depositId"
        :exclude-pending="true"
        :exclude-timeouts="true"
      >
        {{ $fc(participation.depositAmount, participation.depositCurrency) }}
      </deposit-payments-hint>
    </span>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'

import get from 'lodash/get'

export default {
  name: 'cell-deposit',
  components: { DepositPaymentsHint },
  props: {
    participation: {
      type: Participation,
      required: true,
    }
  },
  computed: {
    depositId () {
      return get(this.participation, 'depositIds[0]', '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/won";
</style>
