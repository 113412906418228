<template>
  <div class="cell-invoice won-cmn__cell">
    <template v-if="invoice">
      <ui-button
        v-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)"
        class="cell-invoice__delete-btn"
        fill="none"
        :title="$t('REMOVE_HINT')"
        @click="remove"
      >
        <ui-icon
          class="cell-invoice__delete-btn-icon"
          icon="close"
        />
      </ui-button>

      <a
        class="
          won-cmn__str
          won-cmn__str_sec
          cell-invoice__download-link
        "
        :title="invoice.fileName"
        :href="invoice.s3Link"
        target="_blank"
        rel="noopener"
      >
        {{ formattedInvoiceFileName }}
      </a>
    </template>

    <template v-else-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)">
      <ui-button
        class="cell-invoice__add-btn"
        fill="none"
        :is-disabled="isSubmitting"
        @click="add"
      >
        <ui-icon
          class="cell-invoice__add-btn-icon"
          icon="plus"
        />
        {{ $t('ADD_BTN') }}
      </ui-button>
    </template>

    <template v-else>
      <span class="won-cmn__str won-cmn__str_sec">
        {{ $t('NO_INVOICE_YET_MSG') }}
      </span>
    </template>

    <ui-spinner
      v-if="isSubmitting"
      class="cell-invoice__spinner"
      type="pills"
      overlay
    />
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { UiButton, UiIcon, UiSpinner } from '@shelf.network/ui-kit'

import { mapGetters, mapActions } from 'vuex'
import { wonGetters, wonActions } from '../store/types'

import { showError, showSuccess } from 'Utils/notifications'
import { confirmAction } from 'Utils/confirmAction'

import { selectPDF } from 'Utils/selectFile'
import { middleEllipsis } from 'Utils/middleEllipsis'

export default {
  name: 'cell-invoice',
  components: {
    UiSpinner,
    UiButton,
    UiIcon,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    }
  },

  data: _ => ({
    isSubmitting: false,
  }),

  computed: {
    ...mapGetters('ui/won', {
      invoices: wonGetters.INVOICES,
    }),

    winner () {
      return this.participation.winner
    },

    invoice () {
      if (this.winner.invoiceId) {
        return this.invoices[this.winner.invoiceId]
      }

      return null
    },

    formattedInvoiceFileName () {
      return this.invoice
        ? middleEllipsis(this.invoice.fileName)
        : ''
    }
  },

  methods: {
    ...mapActions('ui/won', {
      addInvoice: wonActions.ADD_WINNER_INVOICE,
      removeInvoice: wonActions.REMOVE_WINNER_INVOICE,
    }),

    async add () {
      const file = await selectPDF()
      if (!file) {
        return
      }

      this.isSubmitting = true
      try {
        await this.addInvoice({
          participation: this.participation,
          file,
        })
        showSuccess(this.$t('ADD_SUCCESS_NOTIFY'))
      } catch (e) {
        showError(this.$t('ADD_FAILED_NOTIFY'))
      }
      this.isSubmitting = false
    },

    async remove () {
      if (!await confirmAction(this.$t('CONFIRM_REMOVE_MSG'))) {
        return
      }

      this.isSubmitting = true
      try {
        await this.removeInvoice(this.participation)
        showSuccess(this.$t('REMOVE_SUCCESS_NOTIFY'))
      } catch (e) {
        showError(this.$t('REMOVE_FAILED_NOTIFY'))
      }
      this.isSubmitting = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.cell-invoice {
  position: relative;

  &__download-link {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }

  &__delete-btn {
    display: inline;

    /deep/ .ui-button__button {
      padding: 0;
    }

    &-icon {
      font-size: 20px;
      color: $color-flag-is-error;
      position: relative;
      top: 0.05em;
    }
  }

  &__add-btn {
    /deep/ .ui-button__button,
    /deep/ .ui-button__button:hover {
      color: $color-ui-secondary;
      padding: 0;
    }

    &-icon {
      font-size: 14px;
      margin-right: 0.1em;
      color: $color-flag-is-success;
      position: relative;
      top: 0.05em;
    }
  }

  &__spinner {
    background: rgba($color-ui-bg, 0.5);
  }
}
</style>

<i18n>
{
  "en": {
    "ADD_BTN": "Add",
    "REMOVE_HINT": "Remove invoice",
    "NO_INVOICE_YET_MSG": "No invoice yet",
    "ADD_SUCCESS_NOTIFY": "Invoice added",
    "ADD_FAILED_NOTIFY": "Failed to add invoice",
    "REMOVE_SUCCESS_NOTIFY": "Invoice removed",
    "REMOVE_FAILED_NOTIFY": "Failed to remove invoice",
    "CONFIRM_REMOVE_MSG": "Are you sure you want to delete invoice?"
  },
  "ka": {
    "ADD_BTN": "დამატება",
    "REMOVE_HINT": "ინვოისის წაშლა",
    "NO_INVOICE_YET_MSG": "არ არის ინვოისი",
    "ADD_SUCCESS_NOTIFY": "ინვოისი მიება",
    "ADD_FAILED_NOTIFY": "ინვოისი არ დაემატა",
    "REMOVE_SUCCESS_NOTIFY": "ინვოისი წაიშალა",
    "REMOVE_FAILED_NOTIFY": "ინვოისი ვერ წაიშალა",
    "CONFIRM_REMOVE_MSG": "გსურთ ინვოისის წაშლა?"
  },
  "ru": {
    "ADD_BTN": "Добавить",
    "REMOVE_HINT": "Удалить счёт",
    "NO_INVOICE_YET_MSG": "Счёт отсутствует",
    "ADD_SUCCESS_NOTIFY": "Счёт добавлен",
    "ADD_FAILED_NOTIFY": "Не удалось добавить счёт",
    "REMOVE_SUCCESS_NOTIFY": "Счёт удалён",
    "REMOVE_FAILED_NOTIFY": "Не удалось удалить счёт",
    "CONFIRM_REMOVE_MSG": "Вы уверены, что хотите удалить счёт?"
  },
  "uk": {
    "ADD_BTN": "Додати",
    "REMOVE_HINT": "Вилучити рахунок",
    "NO_INVOICE_YET_MSG": "Рахунок відсутній",
    "ADD_SUCCESS_NOTIFY": "Рахунок додано",
    "ADD_FAILED_NOTIFY": "Не вдалося додати рахунок",
    "REMOVE_SUCCESS_NOTIFY": "Рахунок вилучено",
    "REMOVE_FAILED_NOTIFY": "Не вдалося вилучити рахунок",
    "CONFIRM_REMOVE_MSG": "Ви впевнені, що хочете вилучити рахунок?"
  }
}
</i18n>
