<template>
  <div class="cell-payment-status won-cmn__cell">
    <template v-if="isDelivering">
      <div
        v-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)"
        class="cell-payment-status__actions"
      >
        <button
          class="
            cell-payment-status__btn
            cell-payment-status__btn_confirm
          "
          :title="$t('MARK_AS_DELIVERED_HINT')"
          :disabled="isSubmitting"
          @click="setDelivered"
        >
          <ui-icon
            icon="success"
            class="cell-payment-status__btn-icon"
          />
        </button>

        <ui-spinner
          class="cell-payment-status__spinner"
          v-if="isSubmitting"
          type="pills"
          overlay
        />
      </div>

      <span
        v-else
        class="
          won-cmn__str
          won-cmn__str_sec
        "
      >
        {{ $t('NOT_DELIVERED_YET_MSG') }}
      </span>
    </template>

    <template v-else>
      <div
        v-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)"
        class="cell-payment-status__actions"
      >
        <button
          class="
            cell-payment-status__btn
            cell-payment-status__btn_confirm
          "
          :title="$t('MARK_AS_PAID_HINT')"
          :disabled="isSubmitting"
          @click="setPaid"
        >
          <ui-icon
            icon="success"
            class="cell-payment-status__btn-icon"
          />
        </button>

        <button
          class="
              cell-payment-status__btn
              cell-payment-status__btn_cancel
            "
          :title="$t('MARK_AS_REFUSED_HINT')"
          :disabled="isSubmitting"
          @click="setRefused"
        >
          <ui-icon
            icon="close"
            class="cell-payment-status__btn-icon"
          />
        </button>

        <ui-spinner
          class="cell-payment-status__spinner"
          v-if="isSubmitting"
          type="pills"
          :overlay="true"
        />
      </div>
      <span
        v-else
        class="
          won-cmn__str
          won-cmn__str_sec
        "
      >
        {{ $t('NOT_PAID_YET_MSG') }}
      </span>
    </template>
  </div>
</template>

<script>
import { UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import { Participation } from 'Models/Participation'
import { Winner } from 'Models/Winner'

import { mapActions } from 'vuex'
import { wonActions } from 'Components/Won/store/types'

import { showError, showSuccess } from 'Utils/notifications'
import { confirmAction } from 'Utils/confirmAction'
import { getISODate } from 'Utils/dateHelpers'

export default {
  name: 'cell-payment-status',
  components: { UiIcon, UiSpinner },
  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  data: _ => ({
    isSubmitting: false,
  }),

  computed: {
    winner () {
      return this.participation.winner
    },

    isPaid () {
      return this.winner.state === Winner.statesEnum.dealCompleted
    },

    isDelivering () {
      return this.winner.state === Winner.statesEnum.inTransit
    },
  },

  methods: {
    ...mapActions('ui/won', {
      updateWinnerDetails: wonActions.UPDATE_WINNER_DETAILS,
    }),

    async setPaid () {
      if (!await confirmAction(this.$t('CONFIRM_PAID_MSG'))) {
        return
      }

      this.isSubmitting = true
      const successTxt = this.$t('UPDATE_SUCCESS_NOTIFY')
      const failedTxt = this.$t('UPDATE_FAILED_NOTIFY')
      try {
        await this.updateWinnerDetails({
          state: Winner.statesEnum.inTransit,
          participation: this.participation,
        })
        showSuccess(successTxt)
      } catch (e) {
        console.error(e)
        showError(failedTxt)
      }
      this.isSubmitting = false
    },

    async setDelivered () {
      if (!await confirmAction(this.$t('CONFIRM_DELIVERY_MSG'))) {
        return
      }

      this.isSubmitting = true
      const successTxt = this.$t('DELIVERY_SUCCESS_NOTIFY')
      const failedTxt = this.$t('DELIVERY_FAILED_NOTIFY')
      try {
        await this.updateWinnerDetails({
          state: Winner.statesEnum.dealCompleted,
          participation: this.participation,
          deliveredAt: getISODate(),
        })
        showSuccess(successTxt)
      } catch (e) {
        console.error(e)
        showError(failedTxt)
      }
      this.isSubmitting = false
    },

    async setRefused () {
      if (!await confirmAction(this.$t('CONFIRM_REFUSED_MSG'))) {
        return
      }

      this.isSubmitting = true
      const successTxt = this.$t('UPDATE_SUCCESS_NOTIFY')
      const failedTxt = this.$t('UPDATE_FAILED_NOTIFY')
      try {
        await this.updateWinnerDetails({
          state: Winner.statesEnum.dealCanceled,
          participation: this.participation,
        })
        showSuccess(successTxt)
      } catch (e) {
        console.error(e)
        showError(failedTxt)
      }
      this.isSubmitting = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.cell-payment-status {
  &__actions {
    display: grid;
    position: relative;
    grid-auto-flow: column;
    gap: 0.5em;
  }

  &__spinner {
    background: rgba($color-ui-bg, 0.5);
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-color: transparent;
    padding: 0;
    height: 3.5em;
    width: 3.5em;
    transition: all 150ms;

    &_confirm {
      background-color: $color-flag-is-success;
    }

    &_cancel {
      background-color: $color-flag-is-error;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &-icon {
      font-size: 1.5em;
      color: $color-ui-default-contrast;
    }
  }

  &__refused-msg {
    color: $color-flag-is-error;
  }
}

</style>

<i18n>
{
  "en": {
    "UPDATE_SUCCESS_NOTIFY": "Payment status updated",
    "UPDATE_FAILED_NOTIFY": "Failed to update payment status",
    "CONFIRM_PAID_MSG": "Winner's deposit will be returned and Shelf.Network fee will be charged. Continue?",
    "CONFIRM_REFUSED_MSG": "Full deposit amount will be charged from the customer. Continue?",
    "DELIVERY_SUCCESS_NOTIFY": "Lot marked as delivered",
    "DELIVERY_FAILED_NOTIFY": "Failed to mark lot as delivered",
    "CONFIRM_DELIVERY_MSG": "Winner's lot will be marked as delivered. Continue?",
    "MARK_AS_PAID_HINT": "Mark as paid",
    "MARK_AS_REFUSED_HINT": "Mark as refused to pay",
    "NOT_PAID_YET_MSG": "Not paid yet",
    "MARK_AS_DELIVERED_HINT": "Mark as delivered",
    "NOT_DELIVERED_YET_MSG": "Not delivered yet"
  },
  "ka": {
    "UPDATE_SUCCESS_NOTIFY": "გადახდის სტატუსი შეიცვალა",
    "UPDATE_FAILED_NOTIFY": "გადახდის სტატუსი ვერ შეიცვალა",
    "CONFIRM_PAID_MSG": "მოგებულის დეპოზიტი დაბრუნდება და საკომისიო ჩამოიჭრება?",
    "CONFIRM_REFUSED_MSG": "დეპოზიტის მთლიანი რაოდენობა ჩამოიჭრება",
    "DELIVERY_SUCCESS_NOTIFY": "ჩამოსულად მონიშნულია",
    "DELIVERY_FAILED_NOTIFY": "ჩამოსულად ვერ მოინიშნა",
    "CONFIRM_DELIVERY_MSG": "გსურთ ავტომობილის ჩამოსულებში გადატანა?",
    "MARK_AS_PAID_HINT": "გადახდილად მონიშვნა",
    "MARK_AS_REFUSED_HINT": "გადაუხდელად მონიშვნა",
    "NOT_PAID_YET_MSG": "გადაუხდელი",
    "MARK_AS_DELIVERED_HINT": "ჩამოსულად მონიშვნა",
    "NOT_DELIVERED_YET_MSG": "ჯერ არ ჩამოსულა"
  },
  "ru": {
    "UPDATE_SUCCESS_NOTIFY": "Статус оплаты обновлён",
    "UPDATE_FAILED_NOTIFY": "Не удалось обновить статус оплаты",
    "CONFIRM_PAID_MSG": "Депозит победителя будет ему возвращен, при возврате с депозита будет снята плата Shelf.Network. Продолжить?",
    "CONFIRM_REFUSED_MSG": "Полная сумма депозита будет снята с клиента. Продолжить?",
    "DELIVERY_SUCCESS_NOTIFY": "Лот помечен как доставленный",
    "DELIVERY_FAILED_NOTIFY": "Не удалось отметить лот как доставленный",
    "CONFIRM_DELIVERY_MSG": "Лот победителя будет помечен как доставленный. Продолжить?",
    "MARK_AS_PAID_HINT": "Отметить как оплаченный",
    "MARK_AS_REFUSED_HINT": "Отметить как отклоненный в оплате",
    "NOT_PAID_YET_MSG": "Ещё не оплачено",
    "MARK_AS_DELIVERED_HINT": "Отметить как доставленный",
    "NOT_DELIVERED_YET_MSG": "Ещё не доставлен"
  },
  "uk": {
    "UPDATE_SUCCESS_NOTIFY": "Статус оплати оновлено",
    "UPDATE_FAILED_NOTIFY": "Не вдалося оновити статус оплати",
    "CONFIRM_PAID_MSG": "Депозит переможця буде йому повернуто, при поверненні з депозиту буде знята плата Shelf.Network. Продовжити?",
    "CONFIRM_REFUSED_MSG": "Повна сума депозиту буде знята з клієнта. Продовжити?",
    "DELIVERY_SUCCESS_NOTIFY": "Лот позначено як доставлений",
    "DELIVERY_FAILED_NOTIFY": "Не вдалося позначити лот як доставлений",
    "CONFIRM_DELIVERY_MSG": "Лот переможця буде позначено як доставлений. Продовжити?",
    "MARK_AS_PAID_HINT": "Позначити як сплачений",
    "MARK_AS_REFUSED_HINT": "Позначити як відмовлений в оплаті",
    "NOT_PAID_YET_MSG": "Ще не сплачено",
    "MARK_AS_DELIVERED_HINT": "Позначити як доставлений",
    "NOT_DELIVERED_YET_MSG": "Ще не доставлено"
  }
}
</i18n>
