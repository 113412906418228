<template>
  <div class="cell-transportation-price won-cmn__cell">
    <btn-dropdown
      ref="transportationDropdown"
      :show-icon="false"
      :is-disabled="isSubmitting"
      :is-read-only="!$can($USER_CLAIMS.WINNER_MANAGEMENT)"
    >
      <template slot="btn-txt">
        <span class="won-cmn__str won-cmn__str_sec">
          {{
            $fc(winner.transportationPrice, winner.transportationPriceCurrency)
          }}
          <ui-icon
            v-if="$can($USER_CLAIMS.WINNER_MANAGEMENT)"
            class="cell-transportation-price__edit-icon"
            icon="pen"
          />
        </span>
      </template>

      <template slot="content-title">
        {{ $t('DROPDOWN_TITLE') }}
      </template>

      <template slot="content-main">
        <form
          @submit.prevent="apply"
          class="cell-transportation-price__edit-form"
        >
          <number-input
            class="cell-transportation-price__number-input"
            v-model="form.price"
            :is-disabled="isSubmitting"
            :step="100"
          />
          <ui-select
            fill="frame"
            look="secondary"
            v-model="form.currency"
            :options="currencyOptions"
          />
        </form>
      </template>

      <template slot="content-action">
        <ui-button
          @click="apply"
          :is-disabled="isSubmitting"
        >
          {{ $t('APPLY_BTN') }}
        </ui-button>
      </template>
    </btn-dropdown>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'

import { UiButton, UiIcon, UiSelect } from '@shelf.network/ui-kit'
import BtnDropdown from 'Common/BtnDropdown'
import NumberInput from 'Common/NumberInput'

import { mapActions, mapGetters } from 'vuex'
import { wonActions } from 'Components/Won/store/types'
import { enumsGetters } from 'Store/entities/Enums/types'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'cell-transportation-price',
  components: {
    BtnDropdown,
    NumberInput,
    UiButton,
    UiSelect,
    UiIcon,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  data: _ => ({
    form: {
      price: '',
      currency: '',
    },
    isSubmitting: false,
  }),

  computed: {
    ...mapGetters('entities/enums', {
      currencyOptions: enumsGetters.CURRENCIES_AS_OPTIONS,
    }),

    winner () {
      return this.participation.winner
    },
  },

  created () {
    this.form.price = this.winner.transportationPrice
    this.form.currency = this.winner.transportationPriceCurrency
  },

  methods: {
    ...mapActions('ui/won', {
      updateTransportationPrice: wonActions.UPDATE_WINNER_TRANSPORTATION_PRICE,
    }),

    async apply () {
      this.isSubmitting = true
      try {
        await this.updateTransportationPrice({
          participation: this.participation,
          price: String(this.form.price),
          currency: this.form.currency,
        })
        showSuccess(this.$t('APPLY_SUCCESS_NOTIFY'))
      } catch (e) {
        console.error(e)
        showError(this.$t('APPLY_FAILED_NOTIFY'))
      }
      this.isSubmitting = false
      this.close()
    },

    close () {
      this.$refs.transportationDropdown.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.cell-transportation-price {
  &__edit-form {
    display: flex;
    flex-direction: column;
  }

  &__number-input {
    margin-bottom: 1em;
  }

  &__edit-icon {
    color: $color-ui-secondary;
    margin-left: 0.1em;
    position: relative;
    top: 0.09em;
    font-size: 22px;
    line-height: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "APPLY_BTN": "Apply",
    "DROPDOWN_TITLE": "Update Transportation",
    "APPLY_FAILED_NOTIFY": "Failed to update transportation",
    "APPLY_SUCCESS_NOTIFY": "Transportation updated"
  },
  "ka": {
    "APPLY_BTN": "ცვლილება",
    "DROPDOWN_TITLE": "ტრანსპორტირების განახლება",
    "APPLY_FAILED_NOTIFY": "ტრანსპორტირება ვერ შეიცვალა",
    "APPLY_SUCCESS_NOTIFY": "ტრანსპორტირება შეიცვალა"
  },
  "ru": {
    "APPLY_BTN": "Применить",
    "DROPDOWN_TITLE": "Обновить транспортировку",
    "APPLY_FAILED_NOTIFY": "Не удалось обновить транспортировку",
    "APPLY_SUCCESS_NOTIFY": "Транспортировка обновлена"
  },
  "uk": {
    "APPLY_BTN": "Застосувати",
    "DROPDOWN_TITLE": "Оновити транспортування",
    "APPLY_FAILED_NOTIFY": "Не вдалося оновити транспортування",
    "APPLY_SUCCESS_NOTIFY": "Транспортування оновлено"
  }
}
</i18n>
