<template>
  <list-params
    class="won-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="won-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSortValue"
      />

      <list-params-broker
        class="won-list-params__row"
        v-model="form.broker"
      />
    </template>
  </list-params>
</template>

<script>
import {
  WON_QUERY_SORTS,
  WON_QUERY_SORTS_BY_TAB,
  WON_TAB_DEFAULT_SORT,
} from 'Constants/wonUrlParams'
import {
  ListParams,
  ListParamsSort,
  ListParamsBroker,
} from 'Common/ListParams'

export default {
  name: 'won-list-params',
  components: {
    ListParams,
    ListParamsBroker,
    ListParamsSort,
  },

  props: {
    value: { type: Object, required: true },
  },

  data: _ => ({
    form: {
      sort: '',
      broker: '',
    },
  }),

  computed: {
    defaultSortValue () {
      return WON_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    sortOptions () {
      const allSorts = [
        {
          value: WON_QUERY_SORTS.lotEndTimeDesc,
          label: this.$t('SORT_NEW_FIRST_OPT'),
        },
        {
          value: WON_QUERY_SORTS.lotEndTime,
          label: this.$t('SORT_NEW_LAST_OPT'),
        },
        {
          value: WON_QUERY_SORTS.winnerDeliveredAtDesc,
          label: this.$t('SORT_NEWLY_DELIVERED_FIRST_OPT'),
        },
        {
          value: WON_QUERY_SORTS.winnerDeliveredAt,
          label: this.$t('SORT_NEWLY_DELIVERED_LAST_OPT'),
        },
      ]

      return allSorts
        .filter(s => {
          const tab = this.$route.params.tab
          return WON_QUERY_SORTS_BY_TAB[tab].includes(s.value)
        })
    },
  }
}
</script>

<i18n>
{
  "en": {
    "SORT_NEW_FIRST_OPT": "New first",
    "SORT_NEW_LAST_OPT": "New last",
    "SORT_NEWLY_DELIVERED_FIRST_OPT": "Newly delivered first",
    "SORT_NEWLY_DELIVERED_LAST_OPT": "Newly delivered last"
  },
  "ka": {
    "SORT_NEW_FIRST_OPT": "ბოლო მომართვები",
    "SORT_NEW_LAST_OPT": "ადრინდელი მომართვები",
    "SORT_NEWLY_DELIVERED_FIRST_OPT": "ახალი",
    "SORT_NEWLY_DELIVERED_LAST_OPT": "ძველი"
  },
  "ru": {
    "SORT_NEW_FIRST_OPT": "Сначала новые",
    "SORT_NEW_LAST_OPT": "Сначала старые",
    "SORT_NEWLY_DELIVERED_FIRST_OPT": "Сначала недавно доставленные",
    "SORT_NEWLY_DELIVERED_LAST_OPT": "Сначала давно доставленные"
  },
  "uk": {
    "SORT_NEW_FIRST_OPT": "Спочатку нові",
    "SORT_NEW_LAST_OPT": "Спочатку старі",
    "SORT_NEWLY_DELIVERED_FIRST_OPT": "Спочатку нещодавно доставлені",
    "SORT_NEWLY_DELIVERED_LAST_OPT": "Спочатку давно доставлені"
  }
}
</i18n>
