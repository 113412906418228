<template>
  <div class="won-list-item">
    <div class="won-cmn__row">
      <cell-lot
        class="won-list-item__cell"
        :lot="item.lot"
      />

      <cell-winner
        class="won-list-item__cell"
        :participation="item"
      />

      <cell-vehicle-price
        class="won-list-item__cell"
        :participation="item"
        :readonly="template !== LIST_TEMPLATES.invoice"
      />

      <cell-transportation-price
        class="won-list-item__cell"
        :participation="item"
      />

      <cell-deposit
        class="won-list-item__cell"
        :participation="item"
      />

      <cell-invoice
        class="won-list-item__cell"
        :participation="item"
      />

      <template v-if="template === LIST_TEMPLATES.done">
        <cell-delivery
          class="won-list-item__cell"
          :participation="item"
        />
      </template>

      <template v-else>
        <cell-payment-status
          class="won-list-item__cell"
          :participation="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { Participation } from 'Models/Participation'
import { LIST_TEMPLATES } from '../constants'

import CellLot from './CellLot'
import CellWinner from './CellWinner'
import CellInvoice from './CellInvoice'
import CellDeposit from './CellDeposit'
import CellVehiclePrice from './CellVehiclePrice'
import CellPaymentStatus from './CellPaymentStatus'
import CellTransportationPrice from './CellTransportationPrice'
import CellDelivery from './CellDelivery'

export default {
  name: 'won-list-item',
  components: {
    CellLot,
    CellWinner,
    CellDeposit,
    CellInvoice,
    CellVehiclePrice,
    CellPaymentStatus,
    CellTransportationPrice,
    CellDelivery,
  },

  props: {
    item: {
      type: Participation,
      required: true,
    },

    template: {
      type: String,
      default: LIST_TEMPLATES.invoice,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    LIST_TEMPLATES: () => LIST_TEMPLATES
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.won-list-item {
  border-radius: 1em;
  transition: all 150ms, margin 0ms;
  position: relative;
  margin-bottom: 0.7em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid $color-grey;
    transition: all 0ms;
  }

  &:hover {
    background: $color-ui-bg;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    &::after {
      opacity: 0;
    }
  }
}

</style>
