<template>
  <span
    class="participation-lead-link"
    :class="'active-trades-cmn__str active-trades-cmn__str_nb'"
  >
    <button
      class="participation-lead-link__btn"
      :title="hasLead ? $t('OPEN_LEAD_HINT') : participation.fullName"
      :disabled="!hasLead"
      @click="isOverviewShown = true"
    >
      <span
        class="participation-lead-link__name"
        :class="'active-trades-cmn__str active-trades-cmn__str_nb'"
      >
        {{ participation.fullName }}
      </span>

      <ui-icon
        v-if="hasLead"
        class="participation-lead-link__ico"
        icon="link"
      />
    </button>

    <template v-if="isOverviewShown">
      <lead-overview
        class="participation-lead-link__lead-overview"
        :lead-id="participation.leadId"
        @close="isOverviewShown = false"
      />
    </template>
  </span>
</template>

<script>
import { Participation } from 'Models/Participation'
import { UiIcon } from '@shelf.network/ui-kit'
import LeadOverview from 'Common/LeadOverview'

export default {
  name: 'participation-lead-link',

  components: {
    UiIcon,
    LeadOverview,
  },

  props: {
    participation: {
      type: Participation,
      required: true,
    },
  },

  data () {
    return {
      isOverviewShown: false,
    }
  },

  computed: {
    hasLead () {
      return Boolean(this.participation.leadId)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/active-trades";

.participation-lead-link {
  &__name {
    color: inherit;
  }

  &__btn {
    background: none;
    border: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    text-align: start;

    &:disabled {
      cursor: text;
      user-select: text;
      color: inherit;
    }
  }

  &__ico {
    color: $color-sys-info;
  }
}
</style>

<i18n>
{
  "en": {
    "OPEN_LEAD_HINT": "Open lead"
  },
  "ka": {
    "OPEN_LEAD_HINT": "ლიდის გახსნა"
  },
  "ru": {
    "OPEN_LEAD_HINT": "Открыть лида"
  },
  "uk": {
    "OPEN_LEAD_HINT": "Відкрити ліда"
  }
}
</i18n>
