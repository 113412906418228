<template>
  <div class="won-list-head won-cmn__row">
    <span class="won-list-head__cell">
      {{ $t('LOT_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('WINNER_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('VEHICLE_PRICE_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('TRANSPORTATION_PRICE_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('DEPOSIT_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('INVOICE_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      <template v-if="template === LIST_TEMPLATES.done">
        {{ $t('DELIVERY_HEAD_CELL') }}
      </template>
      <template v-else>
        {{ $t('PAYMENT_STATUS_HEAD_CELL') }}
      </template>
    </span>
  </div>
</template>

<script>
import { LIST_TEMPLATES } from '../constants'

export default {
  name: 'won-list-head',

  props: {
    template: {
      type: String,
      default: LIST_TEMPLATES.invoice,
      validator (value) {
        return Object.values(LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    LIST_TEMPLATES: () => LIST_TEMPLATES,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/won";

.won-list-head {
  &__cell {
    @extend .won-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}

</style>

<i18n>
{
  "en": {
    "LOT_HEAD_CELL": "CAR",
    "WINNER_HEAD_CELL": "WINNER",
    "VEHICLE_PRICE_HEAD_CELL": "VEHICLE",
    "TRANSPORTATION_PRICE_HEAD_CELL": "TRANSPORTATION",
    "DEPOSIT_HEAD_CELL": "DEPOSIT",
    "INVOICE_HEAD_CELL": "INVOICE",
    "PAYMENT_STATUS_HEAD_CELL": "PAYMENT STATUS",
    "DELIVERY_HEAD_CELL": "DELIVERY"
  },
  "ka": {
    "LOT_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "WINNER_HEAD_CELL": "ᲛᲝᲒᲔᲑᲣᲚᲘ",
    "VEHICLE_PRICE_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "TRANSPORTATION_PRICE_HEAD_CELL": "ᲢᲠᲐᲜᲡᲞᲝᲠᲢᲘᲠᲔᲑᲐ",
    "DEPOSIT_HEAD_CELL": "ᲓᲔᲞᲝᲖᲘᲢᲘ",
    "INVOICE_HEAD_CELL": "ᲘᲜᲕᲝᲘᲡᲘ",
    "PAYMENT_STATUS_HEAD_CELL": "ᲒᲐᲓᲐᲮᲓᲘᲡ ᲡᲢᲐᲢᲣᲡᲘ",
    "DELIVERY_HEAD_CELL": "ჩამოსული"
  },
  "ru": {
    "LOT_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПОБЕДИТЕЛЬ",
    "VEHICLE_PRICE_HEAD_CELL": "ЦЕНА",
    "TRANSPORTATION_PRICE_HEAD_CELL": "ТРАНСПОРТИРОВКА",
    "DEPOSIT_HEAD_CELL": "ДЕПОЗИТ",
    "INVOICE_HEAD_CELL": "СЧËТ",
    "PAYMENT_STATUS_HEAD_CELL": "СТАТУС ОПЛАТЫ",
    "DELIVERY_HEAD_CELL": "ДОСТАВКА"
  },
  "uk": {
    "LOT_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПЕРЕМОЖЕЦЬ",
    "VEHICLE_PRICE_HEAD_CELL": "ЦІНА",
    "TRANSPORTATION_PRICE_HEAD_CELL": "ТРАНСПОРТУВАННЯ",
    "DEPOSIT_HEAD_CELL": "ДЕПОЗИТ",
    "INVOICE_HEAD_CELL": "РАХУНОК",
    "PAYMENT_STATUS_HEAD_CELL": "СТАТУС ОПЛАТИ",
    "DELIVERY_HEAD_CELL": "ДОСТАВКА"
  }
}
</i18n>
